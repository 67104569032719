var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],ref:"container",staticClass:"fill-height"},[_c('v-tabs',{ref:"tabs",attrs:{"active-class":"white","background-color":"grey lighten-3","hide-slider":"","show-arrows":""}},[_c('v-tab',{attrs:{"to":{
                name: 'calculationParams',
                params: {
                    id: _vm.id,
                    calculation: _vm.calculation,
                    settings: _vm.settings,
                },
            }}},[_vm._v(" Parameters ")]),_c('v-tab',{attrs:{"to":{
                name: 'calculationInputs',
                params: {
                    id: _vm.id,
                    calculation: _vm.calculation,
                    settings: _vm.settings,
                },
            }}},[_vm._v(" Inputs ")])],1),_c('v-card',{attrs:{"loading":_vm.loading,"flat":""}},[_c('v-card-title',{ref:"title",staticClass:"py-3"},[_c('v-row',[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-col',{staticClass:"ml-n2 mr-1 mt-n1",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"small":"","fab":"","depressed":"","icon":""},on:{"click":_vm.show}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1)],1):_vm._e(),_c('v-col',{class:{
                        'd-flex pl-4': _vm.$vuetify.breakpoint.smAndDown,
                        'd-flex': !_vm.$vuetify.breakpoint.smAndDown,
                    },attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 10 : 12}},[(_vm.calculation)?_c('span',[_vm._v(_vm._s(_vm.calculation.title))]):_vm._e(),_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","icon":""},on:{"click":_vm.openTitleForm}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)],1),_c('v-spacer'),_c('v-btn',{staticClass:"mx-1",attrs:{"loading":_vm.loading,"disabled":_vm.saveState,"depressed":"","rounded":"","color":"success"},on:{"click":_vm.updateCalculation}},[_vm._v("Save")])],1),_c('v-card-subtitle',{ref:"subtitle",staticClass:"mt-0"},[_c('v-divider')],1),_c('v-card-text',{staticClass:"overflow-y-auto",style:(`height: ${_vm.height}px`)},[_c('router-view')],1)],1),_c('v-dialog',{attrs:{"retain-focus":false,"persistent":"","max-width":"400px"},model:{value:(_vm.openTitleUpdate),callback:function ($$v) {_vm.openTitleUpdate=$$v},expression:"openTitleUpdate"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Update Name")]),_c('v-card-text',[_c('v-form',{ref:"nameCalculation"},[_c('v-text-field',{attrs:{"rules":[_vm.rules.required],"prepend-icon":"mdi-file-document-edit","label":"Name *","required":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1)],1),_c('v-card-actions',[_c('v-row',{staticClass:"mx-3 mb-3"},[_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.openTitleUpdate = false}}},[_vm._v(" Close ")]),_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.updateTitleLoading,"text":"","color":"error"},on:{"click":_vm.updateTitle}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }