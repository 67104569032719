<template>
    <div v-resize="onResize" class="fill-height" ref="container">
        <v-tabs
            ref="tabs"
            active-class="white"
            background-color="grey lighten-3"
            hide-slider
            show-arrows
        >
            <v-tab
                :to="{
                    name: 'calculationParams',
                    params: {
                        id: id,
                        calculation: calculation,
                        settings,
                    },
                }"
            >
                Parameters
            </v-tab>

            <v-tab
                :to="{
                    name: 'calculationInputs',
                    params: {
                        id: id,
                        calculation: calculation,
                        settings,
                    },
                }"
            >
                Inputs
            </v-tab>
        </v-tabs>
        <v-card :loading="loading" flat>
            <v-card-title class="py-3" ref="title">
                <v-row>
                    <v-col
                        v-if="$vuetify.breakpoint.smAndDown"
                        class="ml-n2 mr-1 mt-n1"
                        cols="1"
                    >
                        <v-btn small fab depressed @click="show" icon>
                            <v-icon>
                                mdi-chevron-left
                            </v-icon>
                        </v-btn>
                    </v-col>
                    <v-col
                        :cols="$vuetify.breakpoint.smAndDown ? 10 : 12"
                        :class="{
                            'd-flex pl-4': $vuetify.breakpoint.smAndDown,
                            'd-flex': !$vuetify.breakpoint.smAndDown,
                        }"
                    >
                        <span v-if="calculation">{{ calculation.title }}</span>
                        <v-btn small icon class="ml-2" @click="openTitleForm">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-spacer />
                <v-btn
                    :loading="loading"
                    @click="updateCalculation"
                    :disabled="saveState"
                    class="mx-1"
                    depressed
                    rounded
                    color="success"
                    >Save</v-btn
                >
            </v-card-title>
            <v-card-subtitle ref="subtitle" class="mt-0">
                <v-divider />
            </v-card-subtitle>
            <v-card-text class="overflow-y-auto" :style="`height: ${height}px`">
                <router-view />
            </v-card-text>
        </v-card>
        <v-dialog
            :retain-focus="false"
            v-model="openTitleUpdate"
            persistent
            max-width="400px"
        >
            <v-card>
                <v-card-title class="text-h5">Update Name</v-card-title>
                <v-card-text>
                    <v-form ref="nameCalculation">
                        <v-text-field
                            v-model="title"
                            :rules="[rules.required]"
                            prepend-icon="mdi-file-document-edit"
                            label="Name *"
                            required
                        />
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-row class="mx-3 mb-3">
                        <v-btn
                            text
                            color="secondary"
                            @click="openTitleUpdate = false"
                        >
                            Close
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            :loading="updateTitleLoading"
                            @click="updateTitle"
                            text
                            color="error"
                        >
                            Save
                        </v-btn>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import API from '@/services/api'
import _ from 'lodash'
export default {
    name: 'Calculation',
    components: {},
    props: {
        id: String,
        calculation: {
            type: Object,
            default() {
                return {}
            },
        },
        settings: Array,
    },
    data() {
        return {
            saveState: true,
            height: 0,
            title: '',
            openTitleUpdate: false,
            updateTitleLoading: false,
            localCalculation: null,
            loading: false,
            error: null,
            rules: {
                required: v => !!v || 'Required',
            },
        }
    },
    updated() {
        this.onResize()
    },
    mounted() {
        this.title = this.calculation.title
        this.localCalculation = _.cloneDeep(this.calculation)
        this.$root.$on('replaceCalculation', calculation => {
            this.saveState = false
            this.calculation = calculation
        })
        this.$router.push({
            name: 'calculationParams',
            params: {
                id: this.calculation.id,
                calculation: this.calculation,
                settings: this.settings,
            },
        })
    },
    watch: {
        id: async function(newId) {
            try {
                this.localCalculation = _.cloneDeep(this.calculation)
                this.$router.push({
                    name: 'calculationParams',
                    params: {
                        id: newId,
                        calculation: this.calculation,
                        settings: this.settings,
                    },
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
    computed: {
        ...mapState(['saveBtn']),
        ...mapGetters(['getAlternatives']),
    },
    async created() {
        try {
            this.loading = true
            this.error = null
            this.newCalculation = {}
            const {
                data: { user },
            } = await API.getMyInfo()
            const quoteDetails = user.permissions.find(x => x == 'quoteDetails')
            if (quoteDetails) {
                this.calculationDetails = true
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                tabs: {
                    $el: { clientHeight: tabsHeight },
                },
                title: { clientHeight: titleHeight },
                subtitle: { clientHeight: subtitleHeight },
            } = this.$refs
            this.height =
                containerHeight - tabsHeight - titleHeight - subtitleHeight
        },
        show() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.$emit('show')
            }
        },
        ...mapActions(['replaceCalculationAction']),
        openTitleForm() {
            this.title = this.calculation.title
            this.openTitleUpdate = true
        },
        updateCalculation: async function() {
            try {
                this.loading = true
                this.error = null
                let changes = {}
                Object.keys(this.calculation).forEach(key => {
                    if (
                        (this.localCalculation[key] === undefined &&
                            this.calculation[key] !== undefined) ||
                        (this.calculation[key] !== undefined &&
                            JSON.stringify(this.calculation[key]) !==
                                JSON.stringify(this.localCalculation[key]))
                    ) {
                        changes[key] = this.calculation[key]
                    }
                })
                await API.updateCalculation(this.id, changes)
                this.localCalculation = _.cloneDeep(this.calculation)
                this.$root.$emit('replaceCalculation', this.calculation)
                this.loading = false
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.openTitleUpdate = false
            }
        },
        async updateTitle() {
            try {
                this.updateTitleLoading = true
                const title = { title: this.title }
                await API.updateCalculation(this.id, title)
                this.calculation.title = this.title
                this.updateTitleLoading = false
                this.openTitleUpdate = false
                this.$root.$emit('replaceCalculation', this.calculation)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        replaceCalculation(calculation) {
            this.saveState = true
            this.calculation = calculation
        },
    },
}
</script>
